import axios from 'axios'
import { createMutex } from 'lib0/mutex.js'
import { cloneDeep, isEmpty } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
// @ts-ignore
import { AceBinding } from '@/assets/javascript/y-ace.js'
import { ADVANCEDIDETYPE, IDECONSTANT, SYNC_ACTIONS } from '@/utils/ide'

import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import {
  LiveCodeActionsType,
  useLiveCodeStore,
  type ILiveCodeActions
} from '@/stores/liveCode.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useProjectManager } from '@/stores/projectManager.store'

import editorService from '@/services/ide/editor.service'
import projectTreeService, { type ISyncRequest } from '@/services/ide/projectTree.service'
import type { IsaveProjectActualRequest } from '@/services/ide/projects.service'
import projectsService from '@/services/ide/projects.service'

let binding: any = null
let provider: any = null
let eventProvider: WebsocketProvider | null = null
let isGetNewActionFetching: boolean = false
let removeCollabStatus: boolean = false

/**
 * @description - post the script to the server
 * @param script - passing the script as params
 * @param initActionListListner - check for advance IDE
 * @param count - send the count
 * @returns call the function after execution of func
 */
const postSetScript = async (
  script: string,
  initActionListListner: boolean = false,
  count: number = 0
) => {
  if (!useIdeStore().isWindowAce()) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postSetScript(script, initActionListListner, count + 1)
    }
  } else {
    subscribeToDocument(script, initActionListListner)
  }
}

/**
 * @description - open the shared file
 * @param initActionListListner - check for advance IDE
 * @param multiFile - check for advance IDE
 */
const openSharedFile = async (
  initActionListListner: boolean = false,
  multiFile: ISyncRequest = {}
) => {
  const projectStore = useProjectManager().projectPermissionWithId
  const language = projectStore?.language ? projectStore?.language : useIdeStore().isLanguage
  const projectId = projectStore?.id ? projectStore?.id : useIdeStore().isProjectId
  const isAdvanced = useIdeStore().isAdvanced
  const reqBodyForSingleProj = {
    lang: language,
    type: ADVANCEDIDETYPE.OPEN,
    projectKey: isAdvanced ? useIdeStore().projectKey : null,
    projectId,
    shareId: isAdvanced ? (useIdeStore().isShareId as string) : null,
    isInstant: isAdvanced ? useIdeStore().isInstantShare : null
  }
  const reqBodyForMultiProj = {
    ...multiFile,
    projectId,
    lang: language
  }

  const reqObj = isEmpty(multiFile) ? reqBodyForSingleProj : reqBodyForMultiProj

  await axios
    .post(`/ccollab/openSharedFile`, reqObj)
    .then(async (response: any) => {
      await postSetScript(response?.data?.id, initActionListListner)
      if (initActionListListner) {
        useLiveCodeStore().setIsActionListnerInit(false)
        await initActionList()
        onChange(initActionList)
      }
      useLiveCodeStore().setIsLiveCodingActive(true)
      if (initActionListListner) removeCollabStatus = true
    })
    .catch((error) => {
      throw error
    })
}
/**
 * @description - close the shared file
 * @param projectData - closing the project and resting the data
 */
const closeSharedFile = async (projectData: IsaveProjectActualRequest) => {
  const id = projectData?.id ? projectData?.id : useIdeStore().isProjectId
  if (id === -1 || id === null) return
  const reqBody = {
    id,
    lang: projectData?.lang ? projectData?.lang : useIdeStore().isLanguage
  }
  await axios
    .post(`/ccollab/cleanDoc`, reqBody)
    .then((response: any) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @description - subscribe to the document
 * @param id - send the id of the project
 * @param initActionListListner - check for advance IDE
 */
const subscribeToDocument = (id: string, initActionListListner: boolean = false) => {
  const ydoc = new Y.Doc()
  const eventYdoc = new Y.Doc()
  const type = ydoc.getText('ace')
  const WS_SERVER_URL = '/ccollab'
  provider = new WebsocketProvider(WS_SERVER_URL, id, ydoc)
  const mux = createMutex()
  binding = new AceBinding(type, useIdeStore().codeEditor, provider.awareness, mux)

  const user = {
    id: useAuthStore().userEmail,
    name: useAuthStore().firstName,
    color: '#' + Math.floor(Math.random() * 16777215).toString(16)
  }
  provider.awareness.setLocalStateField('user', user)
  if (initActionListListner) {
    eventProvider = new WebsocketProvider(WS_SERVER_URL, id + '-events', eventYdoc, {
      params: { userId: useOrganisationStore().userId?.toString() ?? '' }
    })
    const eventMux = createMutex()
    useLiveCodeStore().initBinding(eventProvider.awareness, eventMux)
    eventProvider.awareness.setLocalStateField('user', user)
    eventProvider.awareness.setLocalStateField('actionsList', [])
  }
}

/**
 * @description - post the await live code emit
 * @param count - send the count
 * @returns - null as just wait for the emit or not
 */
const postAwaitLiveCodeEmit = async (count: number = 0): Promise<null> => {
  if (useLiveCodeStore().awaitLiveCodeEmit) {
    if (count > 30) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postAwaitLiveCodeEmit(count + 1)
    }
  } else {
    return null
  }
}
/**
 * @description disconnecting the live coding
 * @param killEventBinding - kill the event binding
 * @param awaitLiveCodeEmit - await the live code emit
 */
const disconnectLiveCoding = async (
  killEventBinding: boolean = true,
  awaitLiveCodeEmit: boolean = false
) => {
  if (awaitLiveCodeEmit) {
    useLiveCodeStore().setAwaitLiveCodeEmit(true)
    setTimeout(() => {
      useLiveCodeStore().setAwaitLiveCodeEmit(false)
    }, 3000)
    await postAwaitLiveCodeEmit()
  }
  try {
    await provider?.disconnect()
    if (killEventBinding) onChageOff()
    await binding?.destroy()
    if (killEventBinding) useLiveCodeStore().disconnectBinding()
    useLiveCodeStore().setIsLiveCodingActive(false)
    if (!killEventBinding) editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '', false)
  } catch (error) {
    ;() => {}
  }
}

/**
 * @description on live code action
 */
const onLiveCodeAction = async () => {
  const actions = await awaitToGetNewActions()
  actions.forEach(async (action: ILiveCodeActions) => {
    switch (action.type) {
      case LiveCodeActionsType.SYNC_PROJECT_TREE:
        useIdeStore().project.treeData = action.data.treeData || useIdeStore().project.treeData
        useIdeStore().project.home = action.data.home || useIdeStore().project.home

        break
      case LiveCodeActionsType.SYNC_INACTIVE_ITEMS:
        await projectTreeService.setInactiveItem(action?.data?.activeItem || {})
        await projectTreeService.sync(false, true, false)

        break
      case LiveCodeActionsType.SYNC_UPLOAD_PROJECT_FILE:
        await projectTreeService.uploadFileForLiveCodingSync(
          cloneDeep(action.data),
          useIdeStore().project.treeData,
          SYNC_ACTIONS.NEW_ITEM
        )

        await projectTreeService.uploadFileForLiveCodingSync(
          cloneDeep(action.data),
          useIdeStore().project.treeData,
          SYNC_ACTIONS.FILE_CHANGED
        )

        break
      case LiveCodeActionsType.SYNCV_LIBRARY:
        useIdeStore().libraries = action.data.libraries || useIdeStore().libraries
        break
      case LiveCodeActionsType.MAKE_HOME:
        await projectTreeService.makeHome(action.data.item, true)

        break
      case LiveCodeActionsType.LIVE_WITH_EDIT:
        if (action.data.collaboratorEmail === useAuthStore().userEmail) {
          useProjectManager().setProjectPermissionWithId({
            ...useProjectManager().projectPermissionWithId,
            readOnly: false
          })
          editorService.setCodeEditorReadOnly()
        }
        break
      case LiveCodeActionsType.LIVE_WITH_VIEW:
        if (action.data.collaboratorEmail === useAuthStore().userEmail) {
          useProjectManager().setProjectPermissionWithId({
            ...useProjectManager().projectPermissionWithId,
            readOnly: true
          })
          editorService.setCodeEditorReadOnly()
        }
        break
      case LiveCodeActionsType.REMOVE_COLLAB:
        if (action.data.collaboratorEmail === useAuthStore().userEmail && !removeCollabStatus) {
          if (useLiveCodeStore().isLiveCodingActive) await disconnectLiveCoding()
          useProjectManager().setProjectPermissionWithId({
            ...useProjectManager().projectPermissionWithId,
            readOnly: true
          })
          useIdeStore().setCodeUpdated(false)
          projectsService.deleteExistingProjectInSession(action?.data?.projectId)
          window.location.replace('/start-coding')
        }
        break
      case LiveCodeActionsType.CALL_COLLAB:
        await projectsService.listOfCollaborator(
          useIdeStore().isSelectedProject
            ? useIdeStore().isSelectedProject
            : useIdeStore().isProject
        )
        break
    }
  })
  removeCollabStatus = false
}

/**
 * @description - post the init colab
 * @param collabId - send the collab id
 * @param count - send the count
 * @returns - call the function after execution of func
 */
const postInitColab = async (collabId: string | null = null, count: number = 0) => {
  const persistedCode = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()

  if (
    !useLiveCodeStore().isProjectLoaded ||
    ((useIdeStore().genAccessId || useIdeStore().isCollabId) &&
      !useIdeStore().projectKey &&
      useIdeStore().isAdvanced)
  ) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      await postInitColab(collabId, count + 1)
    }
  } else {
    useIdeStore().setSelectedProject(null)
    editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
    useIdeStore().setCodeUpdated(false)
    useIdeStore().isDefaultIde && useProjectManager().setSelectedProject(null)
    if (useIdeStore().isAdvanced) {
      const openShareFileRequest = {
        projectKey: useIdeStore().projectKey,
        name: useIdeStore().activeItem.name,
        path: useIdeStore().activeItem.parent
      }
      await openSharedFile(true, openShareFileRequest).catch(() => {
        editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, persistedCode)
      })
    } else {
      await openSharedFile(true).catch(() => {
        editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, persistedCode)
      })
    }

    await onChange(onLiveCodeAction)
  }
}
/**
 * @description - init the collab
 * @param liveCodingAction - connect live coding according to the boolean
 */
const initColab = async (liveCodingAction: boolean = false) => {
  if (useIdeStore().project === null) return
  const collabId = useLiveCodeStore().collabId || useIdeStore().isCollabId
  const genAccessId = useIdeStore().genAccessId
  const genAccessShare = useIdeStore()?.project?.hasGeneralAccessLink
  useLiveCodeStore().setIsProjectLoaded(false)

  editorService.setCodeEditorReadOnly()

  const collabList = useProjectManager()?.collaboratorList ?? []
  if (
    collabId ||
    liveCodingAction ||
    genAccessShare ||
    genAccessId ||
    collabList.length !== 0 ||
    useProjectManager().projectPermissionWithId?.isOwner === false
  ) {
    await postInitColab(collabId)
  }
}

/**
 * @description - post the live coding initiated
 * @param count - send the count
 * @returns - call the function after execution of func
 */
const postLiveCodingInitiated = async (count: number = 0): Promise<boolean> => {
  if (useLiveCodeStore().isLiveCodingActive == false) return false
  if (!useLiveCodeStore().awareness || !useLiveCodeStore().mux) {
    if (count > 60) {
      return false
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postLiveCodingInitiated(count + 1)
    }
  } else return true
}
/**
 * Await to get the new action list
 * @param count - the count
 * @returns return the new action
 */
const awaitToGetNewActions = async (count: number = 0): Promise<any[]> => {
  if (isGetNewActionFetching) {
    if (count > 20) return []
    else {
      await new Promise((resolve) => setTimeout(resolve, 500))
      return awaitToGetNewActions(count + 1)
    }
  } else {
    return getNewActions()
  }
}
/**
 * @description - getting the new actions
 * @param init - check for init action list boolean value
 * @returns - get the new actions list
 */
const getNewActions = async (init: boolean = false) => {
  try {
    isGetNewActionFetching = true
    const isActionListnerInit = useLiveCodeStore().isActionListnerInit
    const awareness = useLiveCodeStore().awareness
    const localState = awareness?.getLocalState()
    const states = awareness?.getStates()

    let actionsList: ILiveCodeActions[] = localState?.actionsList || []

    const newActions: ILiveCodeActions[] = []

    await states?.forEach((state: any) => {
      if (state?.user?.id !== localState?.user?.id) {
        if (state?.actionsList) {
          state?.actionsList.forEach((action: any) => {
            // if action is not in local actionsList
            if (
              actionsList.findIndex((a: any) => {
                return a.id === action.id || a.timestamp > action.timestamp
              }) === -1
            ) {
              actionsList.push(cloneDeep(action))
              newActions.push(cloneDeep(action))
            }
          })
        }
      }
    })
    actionsList.sort((a: any, b: any) => a.timestamp - b.timestamp)
    newActions.sort((a: any, b: any) => a.timestamp - b.timestamp)
    if (actionsList.length > 100) {
      actionsList = actionsList.slice(actionsList.length - 100)
    }

    awareness?.setLocalStateField('actionsList', actionsList)
    useLiveCodeStore().setIsActionListnerInit(true)
    return init ? [] : isActionListnerInit ? newActions : []
  } catch (e) {
    return []
  } finally {
    isGetNewActionFetching = false
  }
}
/**
 * @description - init the action list
 */
const initActionList = () => {
  if (useLiveCodeStore().isActionListnerInit == false) {
    getNewActions(true)
  }
}
/**
 * @description - on change the callback
 * @param callback - send the callback
 */
const onChange = async (callback: Function) => {
  await postLiveCodingInitiated().then(() => {
    const awareness = useLiveCodeStore().awareness
    awareness?.on('change', (changes: any) => {
      callback(changes)
    })
  })
}

/**
 * @description - on change off
 */
const onChageOff = () => {
  const awareness = useLiveCodeStore().awareness
  awareness?.off('change', () => {})
}
/**
 * @description - broadcast the message
 * @param action - send the action
 * @returns - call the function after execution of func
 */
const broudcastMessage = async (action: ILiveCodeActions) => {
  if (!useLiveCodeStore().isLiveCodingActive) {
    return
  } else {
    return await postLiveCodingInitiated()
      .then(() => {
        // generate timestamp
        action.id = uuidv4()
        action.timestamp = Date.now()
        // get awareness local state
        const awareness = useLiveCodeStore().awareness
        let actionsList = awareness?.getLocalState()?.actionsList || []
        // add action to actionsList
        actionsList.push(action)
        // keep only last 10 actions
        if (actionsList.length > 100) {
          actionsList = actionsList.slice(actionsList.length - 100)
        }

        // use mux to broadcast the action
        const mux = useLiveCodeStore().mux
        if (mux) {
          mux(() => {
            // set awareness local state
            awareness?.setLocalStateField('actionsList', actionsList)
          })
        } else {
          throw new Error('mux is not available')
        }
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        useLiveCodeStore().setAwaitLiveCodeEmit(false)
      })
  }
}

export default {
  initColab,
  openSharedFile,
  closeSharedFile,
  disconnectLiveCoding,
  broudcastMessage,
  getNewActions,
  onChange,
  onChageOff
}
