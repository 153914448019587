import { ACTION_SECTION } from '@/components/organisation/enum/organisation.enums'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import {
  SUBSCRIPTION_PLAN_NAME,
  SUBSCRIPTION_TYPE
} from '@/components/shared/subscription/enum/subscription.enum'
import type { IApiCredentials } from '@/components/shared/subscription/interface/IApiCredentials'
import type { IApiCreditsUsedToday } from '@/components/shared/subscription/interface/IApiCreditsUsedToday'
import type { IPaymentDetails } from '@/components/shared/subscription/interface/IPaymentDetails'
import type { IPaymentMethod } from '@/components/shared/subscription/interface/IPaymentMethod'
import type { IPromotionCodeResponse } from '@/components/shared/subscription/interface/IPromotionCodeResponse'
import type {
  ISubscribeRequest,
  ISubscribeResponse
} from '@/components/shared/subscription/interface/ISubscribeRequest'
import type { ISubscriptionInitRes } from '@/components/shared/subscription/interface/ISubscriptionInitRes'
import gtmService from '@/services/gtm.service'
import posthogService from '@/services/posthog.service'
import utilModelsService from '@/services/util.models.service'
import { useAuthStore } from '@/stores/auth.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { ASSESSMENT_MODAL_HS, AUTHMODELHS } from '@/utils/models'
import axios, { type AxiosResponse } from 'axios'
import { type Router } from 'vue-router'

let router: Router | null = null

/**
 * initiate router
 * @param vueRouter vue router
 */
const initServiceRouter = (vueRouter: Router) => {
  router = vueRouter
}

/**
 * This button is shared and takes action when logged-in
 * If logged-in -> redirect to link or modal from props
 * If not logged-in -> Login modal
 * @param button Button object clicked
 * @param planName Plan name enum
 */
function loginThenRedirect(button: IActionButton, planName?: SUBSCRIPTION_PLAN_NAME) {
  // Not logged in - Open Login Modal
  if (!useAuthStore().isUserloggedIn) {
    // Not logged in - Open Login Modal

    if (planName === SUBSCRIPTION_PLAN_NAME.FREE) {
      useSubscribeStore().setRedirectTo(`/pay/${SUBSCRIPTION_TYPE.BUNDLE}/${planName}`)
    } else {
      useSubscribeStore().setRedirectTo(
        `/pay/${SUBSCRIPTION_TYPE.BUNDLE}/${planName?.toLocaleLowerCase()}`
      )
    }
    utilModelsService.openModal(AUTHMODELHS.LOGIN)
  }
  // If logged in -> Redirect
  if (!useOrganisationStore().activeAccountRoles) return

  if (useAuthStore().isUserloggedIn) {
    //assessment check
    if (button.section == ACTION_SECTION.ASSESSMENTS) {
      if (useOrganisationStore().isIndividualAccount) {
        utilModelsService.openModal(ASSESSMENT_MODAL_HS.SWITCH_TO_ORG)
        return
      }
    }

    //Internal link
    if (button.link) {
      router?.push(button.link)
      // .then(() => router.go(0))
    } else if (button.href) {
      //External HREF
      window.open(button.href, '_blank')
    } else if (button.modal) {
      // Open Modal
      utilModelsService.openModal(button.modal)
    } else {
      // Only for pricing page - cards open modals
      router?.push({
        name: 'pay',
        params: { type: SUBSCRIPTION_TYPE.BUNDLE, plan: planName?.toLocaleLowerCase() }
      })
    }
  }
}

// ===== Compiler API =====

/**
 * Get compiler api subscription data
 * @returns response data
 */
const initCompilerApi = async (): Promise<ISubscriptionInitRes> => {
  return await axios
    .post('/api/compiler-api/init')
    .then((res: any) => {
      const subscribeStore = useSubscribeStore()
      res.data.isCancelled = res.data.isCanceled
      subscribeStore.initApi(res.data)
      if (!res?.data?.plan) {
        subscribeStore.setFakeSubscription()
      }
      return res.data
    })
    .catch((err) => {
      return err
    })
}

/**
 * Get api payment details
 * @returns response data
 */
const getApiPaymentDetail = async (): Promise<IPaymentDetails> => {
  return await axios
    .post('/api/compiler-api/paymentDetail', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get API payment method
 * @returns response data
 */
const getApiPaymentMethod = async (): Promise<IPaymentMethod> => {
  return await axios
    .post('/api/compiler-api/paymentMethod', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * get Api Credentials
 * @returns response data
 */
const getApiCredentials = async (): Promise<IApiCredentials> => {
  return await axios
    .post('/api/compiler-api/credentials', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get API credits used today
 * @returns response data
 */
const getApiCreditsUsedToday = async (): Promise<IApiCreditsUsedToday> => {
  return await axios
    .post('/api/compiler-api/creditUsedToday', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get API payment method
 * @param resultTokenId tokenID generated
 * @returns response data
 */
const updateApiPaymentMethod = async (resultTokenId: string): Promise<IPaymentMethod> => {
  return await axios
    .post('/api/compiler-api/updatePaymentMethod', { token: resultTokenId })
    .then((response: any) => {
      return response.data
    })
    .catch(({ response }) => {
      throw response
    })
}

/**
 * Subscribe to API
 * @param requestObject ISubscribeRequest object
 * @param stripe Stripe api
 * @returns http response object (unused)
 */
const subscribeToApi = async (
  requestObject: ISubscribeRequest,
  stripe?: any
): Promise<AxiosResponse<ISubscribeResponse>> => {
  return await axios
    .post('/api/compiler-api/subscribe', requestObject)
    .then(async (response: any) => {
      await handleStripeNextAction(stripe, response)
      await initCompilerApi()
      posthogService.captureEvent('plan-upgrade', {
        plan: requestObject?.plan,
        type: 'api'
      })
      if (requestObject?.plan !== 'Free') {
        gtmService.sentEvent({ event: 'api_paid', plan: requestObject?.plan, type: 'api' })
      }
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Reactivate API subscription
 * @param req Object
 * @returns http response (unused)
 */
const reactivateApiSubscription = async (req: Object): Promise<any> => {
  return await axios
    .post('/api/compiler-api/reactivateSubscription', req)
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Cancel API subscription
 * @param req Object
 * @returns http response object (unused)
 */
const cancelApiSubscription = async (req: Object): Promise<any> => {
  return await axios
    .post('/api/compiler-api/cancelSubscription', req)
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

// ====== BUNDLE ======

/**
 * Get bundle subscription data
 * @returns ISubscriptionInitRes object
 */
const initBundle = async (): Promise<ISubscriptionInitRes> => {
  return await axios
    .post('/api/bundle/init')
    .then((response: any) => {
      useSubscribeStore().initBundle(response.data)
      return response.data
    })
    .catch((err) => {
      return err
    })
}

/**
 * Subscribe to bundle
 * @param requestObject ISubscribeRequest object
 * @param stripe Stripe api
 * @returns http response object (unused)
 */
const subscribeToBundle = async (
  requestObject: ISubscribeRequest,
  stripe?: any
): Promise<AxiosResponse<ISubscribeResponse>> => {
  return await axios
    .post('/api/bundle/subscribe', requestObject)
    .then(async (response: any) => {
      await handleStripeNextAction(stripe, response)
      posthogService.captureEvent('plan-upgrade', {
        plan: requestObject?.plan,
        type: 'bundle'
      })

      const planType =
        requestObject?.plan == SUBSCRIPTION_PLAN_NAME.TEACHER
          ? 'upgrades_teacher'
          : requestObject?.plan == SUBSCRIPTION_PLAN_NAME.PRO
          ? 'upgrades_pro'
          : requestObject?.plan == SUBSCRIPTION_PLAN_NAME.STUDENT
          ? 'upgrades_student'
          : 'upgrades_free'

      gtmService.sentEvent({ event: planType, plan: requestObject?.plan, type: 'bundle' })

      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get bundle payment method
 * @returns IPaymentMethod data object
 */
const getBundlePaymentMethod = async (): Promise<IPaymentMethod> => {
  return await axios
    .post('/api/bundle/paymentMethod', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Reactivate Bundle subscription
 * @param req Object
 * @returns http response object (unused)
 */
const reactivateBundleSubscription = async (req: Object): Promise<any> => {
  return await axios
    .post('/api/bundle/reactivateSubscription', req)
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Cancel subscription
 * @param req Object
 * @returns http response object (unused)
 */
const cancelBundleSubscription = async (req: Object): Promise<any> => {
  return await axios
    .post('/api/bundle/cancelSubscription', req)
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 *
 * @param id id string
 */
const scrollToElement = (id: string) => {
  const el = document.getElementById(id)
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' })
  }
}

/**
 * @param stripe API object
 * @param response next action response
 */
const handleStripeNextAction = async (stripe: any, response: AxiosResponse<ISubscribeResponse>) => {
  if (!stripe) {
    const subscribeStore = useSubscribeStore()
    // @ts-ignore
    stripe = window.Stripe?.(subscribeStore.bundleInitData?.stripeKey)
  }
  if (response.data.nextAction) {
    if (response.data.nextAction.type === 'use_stripe_sdk') {
      const clientSecret = response.data.clientSecret
      const paymentIntent = await stripe?.confirmCardPayment(clientSecret)
      if (paymentIntent?.error?.type === 'invalid_request_error') {
        throw {
          response: {
            data: {
              message: 'Payment failed due to 3D Secure authentication issues.'
            }
          }
        }
      }
    }
  }
}

/**
 * Fetches a promotion code from the server
 * @param promotionCode A promise that resolves to the promotion code response
 * @returns A promise that resolves to the promotion code response
 * @throws Will throw an error if the request fails
 */
const getPromotionCode = async (promotionCode: string): Promise<IPromotionCodeResponse | void> => {
  return await axios
    .get(`/api/bundle/getPromotionCode/${promotionCode}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Calculates the discount amount and the total price after applying the discount.
 * @param price - The original price as a string with a dollar sign (e.g., "$100.00").
 * @param discount - The discount percentage to be applied (e.g., 20 for 20%).
 * @returns An object containing the original price, the discounted amount, and the total price after discount.
 */
const calcCouponDiscount = (price: string, discount: number) => {
  // Remove the dollar sign from the price and parse it as a number
  const original = parseFloat(price.replace('$', ''))

  // Calculate the discount amount
  const discountedAmount = ((original * discount) / 100).toFixed(2)

  // Calculate the total after applying the discount
  const total = (original - parseFloat(discountedAmount)).toFixed(2)

  // Return the three values
  return {
    original: original.toFixed(2),
    discountedAmount,
    total
  }
}

export {
  initServiceRouter,
  loginThenRedirect,
  scrollToElement,
  // COMPILERAPI
  initCompilerApi,
  getApiPaymentDetail,
  getApiPaymentMethod,
  getApiCredentials,
  updateApiPaymentMethod,
  getApiCreditsUsedToday,
  subscribeToApi,
  cancelApiSubscription,
  reactivateApiSubscription,
  // BUNDLE STARTS HERE
  initBundle,
  subscribeToBundle,
  getBundlePaymentMethod,
  reactivateBundleSubscription,
  cancelBundleSubscription,
  handleStripeNextAction,
  getPromotionCode,
  calcCouponDiscount
}
