import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNavStore = defineStore('nav', () => {
  // ProfileAtLogin
  const isCollapsedHeader = ref<boolean>(false)
  const showWebinarBanner = ref<boolean>(false)
  const showMarketingHeader = ref<boolean>(true)
  const isMarketingPage = ref<boolean>(false)

  const showMobileNav = ref<boolean>(false)

  const showMobileProfileNav = ref<boolean>(false)
  const showNavigationMenus = ref<boolean>(false)

  /**
   * Sets the visibility state of the mobile IDE Navbar navigation.
   * @param val set mobileIdeNav visible state
   */
  const setMobileNav = (val: boolean) => {
    showMobileNav.value = val
  }

  /**
   * Sets the visibility state of the mobile IDE profile navigation.
   * @param val set mobileIdeProfileNav visible state
   */
  const setMobileProfileNav = (val: boolean) => {
    showMobileProfileNav.value = val
  }

  /**
   * Sets isPremium at login
   * @param isCollapsed is Collapsed or Not
   */
  const setIsCollapsed = (isCollapsed: boolean) => {
    isCollapsedHeader.value = isCollapsed
  }
  /**
   * set isMarketingPage value on marging pages
   * @param value is marketing page or Not
   */
  const setIsMarketingPage = (value: boolean) => {
    isMarketingPage.value = value
  }
  /**
   * set showNavigationMenu value on changing views
   * @param value is show navigation menus or Not
   */
  const setShowNavigationMenus = (value: boolean) => {
    showNavigationMenus.value = value
  }

  return {
    isCollapsedHeader,
    setIsCollapsed,
    showWebinarBanner,
    showMarketingHeader,
    isMarketingPage,
    setIsMarketingPage,
    showMobileNav,
    setMobileNav,
    showMobileProfileNav,
    setMobileProfileNav,
    showNavigationMenus,
    setShowNavigationMenus
  }
})
